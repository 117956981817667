<a routerlink="/" *ngIf="template == null" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="150px" height="50px" [src]="logo" alt="Logo" />
  </figure>
</a> 
<a class="default-footer" routerLink="/" *ngIf="template == 'footer'" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img width="150px" height="50px" [lazyLoad]="logo" alt="Logo" defaultImage="/images/pixel.png" />
  </figure>
</a> 
  